import React, { Component } from 'react'
import cn from 'classnames'
import styles from './styles.module.scss'

export default class HeaderMenu extends Component {
  render() {
    const { white, mobile, className, black } = this.props

    return (
      <header className={cn(styles.header, className, { [styles.white]: white, [styles.mobile]: mobile, [styles.black]: black })}>
        {this.isMobile() && (
          <span onClick={this.props.goToMain} className={styles.link}>
            О Проекте
          </span>
        )}
        <span onClick={this.props.goToAbout} className={styles.link}>
          О Фильме
        </span>
        <span onClick={this.props.goToSpeakers} className={styles.link}>
          Спикеры
        </span>
      </header>
    )
  }

  isMobile = () => window.innerWidth < 960 || window.innerHeight < 600
}
