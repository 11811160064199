import React, { Component, Fragment } from 'react'
import { reverse } from 'ramda'
import cn from 'classnames'
import styles from './styles.module.scss'
import ContentSideBar from '../ContentSideBar'

export default class ContentCard extends Component {
  state = {
    openedOne: 0,
    cardNum: 0,
  }
  animationTimer = null
  componentWillUpdate({ cardNum, side }) {
    // if (cardNum !== this.state.cardNum) {
    //   this.setState({ openedOne: 0 })
    // }
  }
  render() {
    const { openedOne, animationClass } = this.state
    const { content, openedSideBar, active, side, cardNum, goToPart } = this.props
    return (
      <Fragment>
        <div className={cn(styles.block, styles[side], { [styles.active]: active })}>
          <div className={styles.content}>
            {this.renderContent()}
          </div>
        </div>
        {active && (
          <ContentSideBar
            opened={openedSideBar}
            content={content}
            setSelected={this.setOpenedOne}
            openedIndex={openedOne}
            closePart={this.closePart}
            cardNum={cardNum}
            openedOne={openedOne}
            goToPart={goToPart}
          />
        )}
      </Fragment>
    )
  }
  renderContent = () => {
    const { content } = this.props
    const { openedOne } = this.state

    const card = content.map((el, i) => (
      <div
        key={i}
        onClick={this.clickCardEvent(i)}
        className={cn(styles.card, { [styles.opened]: i === openedOne })}
      >
        <div 
          style={{
            backgroundImage: `url(/avatars/${el.avatar})`
          }}
          className={styles.avatar}
        />
        <h3 className={styles.name}>{el.name}</h3>
        <div
          onClick={this.openPart}
          className={styles.buttonOpen}
        >Открыть отрывок</div>
      </div>
    ))

    return reverse(card)
  }
  clickCardEvent = index => () => {
    if (index !== this.state.openedOne) this.setState({ openedOne: index })
  }
  openPart = () => {
    this.props.toggleContent(true)
  }
  closePart = () => {
    this.props.toggleContent(false)
  }
  setOpenedOne = index => {
    this.setState({ openedOne: index })
  }
}
