import React, { Component, Fragment, createRef } from 'react'
import cn from 'classnames'
import styles from './styles.module.scss'

export default class ContentSideBar extends Component {
  contentBlock = createRef()
  state = {
    videoId: '',
    play: false,
  }
  mobile = {
    width: 496,
    height: 279
  }
  desktop = {
    width: 960,
    height: 440
  }
  componentWillUpdate({ cardNum, openedOne }) {
    if ((this.props.cardNum !== cardNum || this.props.openedOne !== openedOne) && this.contentBlock.current != null) {
      this.contentBlock.current.scrollTo(0, 0)
    }
  }
  render() {
    const { opened, goToPart, cardNum } = this.props

    const { width, height } = this.getIframeDimensions()

    return (
      <div className={cn(styles.block, { [styles.opened]: opened })}>
        <div className={styles.videoBlock}>
          <iframe
            width={width}
            height={height}
            src={`https://www.youtube.com/embed/${this.getVideoId()}?rel=0&showinfo=0`}
            frameBorder="0"
            title="Чтение отрывка"
          />
        </div>
        <div ref={this.contentBlock} className={styles.contentBlock}>
          <div className={styles.prevNext}>
            <span className={(cardNum === 0 && styles.hidden).toString()} onClick={goToPart(cardNum - 1, true)}>Предыдущий отрывок</span>
            <span className={(cardNum === window.config.content.length - 1 && styles.hidden).toString()} onClick={goToPart(cardNum + 1, true)}>Следующий отрывок</span>
          </div>
          {this.props.content.length > 1 && (
            <div className={styles.readBy}>
              <p>Этот отрывок читают</p>
              <div>
                {this.getReadBy()}
              </div>
            </div>
          )}
          <div className={styles.content}>
            {this.getContent()}
          </div>
        </div>
        <div className={styles.shareContent} />
        <div onClick={this.closeSideBar} className={styles.hide}>
          <span>Свернуть</span>
        </div>
      </div>
    )
  }
  getIframeDimensions = () => {
    return window.innerWidth > 1300 ? this.desktop : this.mobile
  }
  getReadBy = () => {
    const { content, openedIndex } = this.props

    return content.map(({ name }, i) => (
      <span
        key={i}
        className={cn(styles.readerLink, { [styles.active]: i === openedIndex })}
        onClick={this.setReader(i)}
      >
        {name}
      </span>
    ))
  }
  getContent = () => {
    const { openedIndex, content } = this.props
    const contentHuman = content[openedIndex] ? content[openedIndex] : content[0]
    const { name, cv, text } = contentHuman

    return (
      <Fragment>
        <h2>{name}</h2>
        <h3>{cv}</h3>
        <p>{text}</p>
      </Fragment>
    )
  }
  setReader = index => () => {
    this.props.setSelected(index)
  }
  closeSideBar = () => {
    this.props.closePart()
  }
  getVideoId = () => {
    if (!this.props.opened) return ''
    const { openedIndex, content } = this.props
    return content[openedIndex].videoLink
  }
}
