import React, { Component, createRef } from 'react'
import cn from 'classnames'
import styles from './styles.module.scss'

export default class MainScreen extends Component {
  constructor(props) {
    super(props)
    this.gallery = createRef()
  }
  componentDidUpdate() {
    if (this.props.shown) {
      const { current } = this.gallery
      setTimeout(() => {
        current.style.transform = `translateY(-${current.offsetHeight - window.innerHeight}px)`
      }, 1000)
    }
  }
  render() {
    return (
      <div className={styles.block}>
        <div className={styles.left}>
          <div>
            <p className={styles.smallText}>300-летию Невского проспекта посвящается</p>
            <h2>Мультимедийный проект «Невский проспект»</h2>
            <p className={styles.smallText}>Уникальный мультимедийный проект – подарок городу и горожанам. В год, юбилейный для главной магистрали Петербурга, мы объединили больше 1000 человек. Знаменитые деятели культурны, лидеры мнений, представители власти читают отрывки из повести Николая Васильевича Гоголя «Невский проспект». Также, в рамках проекта, в городе прошли съемки полнометражного художественного фильма.</p>
            <div className={styles.bottomContent}>
              <div onClick={this.goToFacts} className={styles.mouse}>
                <div className={styles.img} />
                <p className={styles.smallText}>
                  Нажми сюда<br />для перехода на сайт
                </p>
              </div>
              <div className={styles.withSupport}>
                <p className={styles.smallText}>При поддержке:</p>
                <div className={styles.logos}>
                  <a target="_blank" href="https://gorod-plus.tv/">
                    <div className={styles.gorodPlus} />
                  </a>
                  <a target="_blank" href="https://petrogradweb.ru">
                    <div className={styles.petroLogo} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.gallery} ref={this.gallery}>
            <div className={styles.images}>
              <div className={cn(styles.img, styles.img1, styles.profile1)} />
              <div className={cn(styles.img, styles.img3, styles.profile3)} />
              <div className={cn(styles.img, styles.img1, styles.profile5)} />
              <div className={cn(styles.img, styles.img3, styles.profile7)} />
              <div className={cn(styles.img, styles.img1, styles.profile9)} />
              <div className={cn(styles.img, styles.img3, styles.profile11)} />
              <div className={cn(styles.img, styles.img1, styles.profile13)} />
              <div className={cn(styles.img, styles.img3, styles.profile15)} />
              <div className={cn(styles.img, styles.img2, styles.profile2)} />
              <div className={cn(styles.img, styles.img4, styles.profile4)} />
              <div className={cn(styles.img, styles.img2, styles.profile6)} />
              <div className={cn(styles.img, styles.img4, styles.profile8)} />
              <div className={cn(styles.img, styles.img2, styles.profile10)} />
              <div className={cn(styles.img, styles.img4, styles.profile12)} />
              <div className={cn(styles.img, styles.img2, styles.profile14)} />
              <div className={cn(styles.img, styles.img4, styles.profile16)} />
            </div>
            <div style={this.getFilmDimensions()} className={styles.film}>
              <iframe
                {...this.getFilmIframeDimensions()}
                src="https://www.youtube.com/embed/dr9w-3aW3cc?rel=0&showinfo=0"
                frameBorder="0"
                title="О фильме"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  goToFacts = () => this.props.shown && this.props.goToFacts()
  getFilmIframeDimensions = () => {
    const { innerWidth } = window
    let width, height
    if (innerWidth > 1700) {
      width = 800
      height = 560
    } else {
      width = 747
      height = 352
    }
    return {
      width,
      height
    }
  }
  getFilmDimensions = () => ({
    height: window.innerHeight
  })
}
