import React, { Component, Fragment } from 'react'
import cn from 'classnames'
import styles from './styles.module.scss'
import HeaderMenu from '../HeaderMenu'

const timeout = 12000

export default class FactsScreen extends Component {
  constructor(props) {
    super(props)

    this.facts = this.shuffle(window.config.facts)
    this.factTimer = null
    this.state = {
      currentFact: 0,
      showFact: false,
      factContent: null
    }
  }

  componentWillUpdate({ startFactSlide, shown }, { currentFact }) {
    if (currentFact !== this.state.currentFact || startFactSlide !== this.props.startFactSlide) {
      this.getFact(currentFact)
    }

    if (this.props.videoLoadedProgress == 100 && this.props.shown) {
      this.props.goToVideo()
    }
  }

  render() {
    const { showFact, factContent } = this.state
    const { videoLoadedProgress } = this.props
    return (
      <div className={styles.block}>
        <HeaderMenu
          goToAbout={this.props.goToAbout}
          goToSpeakers={this.props.goToSpeakers}
        />
        <div className={styles.inner}>
          <div className={cn(styles.fact, { [styles.show]: showFact })}>
            {factContent}
          </div>
          <div
            className={styles.progressBar}
          >
            <div
              style={{ width: `${videoLoadedProgress}%` }}
              className={styles.indicator}
            />
            <div className={styles.numeric}>
              {videoLoadedProgress}%
            </div>
          </div>
        </div>
      </div>
    )
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  getFact(currentFact) {
    const { title, content } = this.facts[currentFact]
    this.setState({ showFact: false })

    setTimeout(() => {
      setTimeout(() => {
        let index = currentFact + 1
        if (currentFact + 1 === this.facts.length) {
          index = 0
        }

        this.setState({ currentFact: index })
      }, timeout)

      this.setState({
        factContent: (
          <Fragment>
            <h2>{title}</h2>
            <p>{content}</p>
          </Fragment>
        )
      }, () => this.setState({ showFact: true }))
    }, 400)
  }
}
