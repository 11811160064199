import React, { Component } from 'react'
import styles from './styles.module.scss'
import HeaderMenu from '../HeaderMenu'

export default class sAboutScreen extends Component {
  constructor(props) {
    super(props)

    this.aboutText = window.config.about
  }
  render() {
    return (
      <div className={styles.block}>
        <HeaderMenu
          goToAbout={this.props.goToAbout}
          goToSpeakers={this.props.goToSpeakers}
          goToMain={this.props.goToMain}
          className={styles.header}
          mobile
          black
        />
        <div className={styles.topRow}>
          <span onClick={this.props.goToVideo} className={styles.goBack}>
            Вернуться к интерактивному видео
          </span>
        </div>

        <div className={styles.content}>
          <div className={styles.video}>
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${this.props.opened ? 'dr9w-3aW3cc?rel=0&showinfo=0' : ''}`}
              frameBorder="0"
              title="О фильме"
              allowFullScreen
            />
          </div>
          <div className={styles.text}>
            <h2>О фильме</h2>
            <p>{this.aboutText}</p>
          </div>
        </div>
      </div>
    )
  }
}
