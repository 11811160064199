import React, { Component, createRef } from 'react'
import { unnest, values } from 'ramda'
import cn from 'classnames'
import styles from './styles.module.scss'
import ContentCard from '../ContentCard'
import HeaderMenu from '../HeaderMenu'

const frameCount = 1368
const playBackConst = 10
const outerHeight = frameCount * playBackConst + window.innerHeight
const imgPull = 4

export default class VideoScreen extends Component {
  constructor(props) {
    super(props)

    this.video = createRef()
    this.videoBlock = createRef()
    this.readersScrollContainer = createRef()
    this.secondCounter = 0

    this.content = window.config.content
    this.state = {
      currentCard: 0,
      cardContent: null,
      readersShown: false,
      contentShown: false,
      openedSideBar: false,
      prevOpenedSideBar: false,
      currentImg: 0,
    }
  }
  componentDidUpdate() {
    if (this.props.shown) {
      
    } else {
      // window.scrollTo(0, 0)
    }
  }
  shouldComponentUpdate() {
    if (!this.props.shown) return false
    return true
  }
  componentDidMount() {
    const block = this
    let loadedCounter = 0

    this.imgPreloader = []

    let i = 0
    let progress = 0
    const onePercent = frameCount / 100
    let  imgPath = 'one'

    const loadImg = () => {
      if (i >= frameCount) {
        if (imgPath === 'two') return
        i = 0
        imgPath = 'two'
      }
      i += 4
      const images = []
      const counter = i
      for (let ind = 0; ind < imgPull; ind++) {
        const imgSrc = this.getImageName(counter - ind, imgPath)

        const load = imgSrc => {
          fetch(imgSrc)
            .then(response =>  {
              const img = new Image()
              img.src = imgSrc

              loadedCounter++
              const realProgress = Math.round((counter - imgPull + ind) / onePercent)
              if (progress < realProgress) {
                progress = realProgress
                this.props.setProgress(progress)
              }

              if (imgPath === 'two') {
                this.secondCounter = counter
              }

              images.push(ind)
              this.imgPreloader.push(img)

              if (images.length === imgPull) {
                loadImg()
              }
            })
            .catch(err => {
              load(imgSrc)
            })
        }
        load(imgSrc)
      }
    }

    loadImg()

    this.videoBlock.current.style.height = `${outerHeight}px`
    this.sliceVideoAndListenForCards()
    this.scrollContainer = document.getElementById('scrollScreen')
    window.requestAnimationFrame(this.scrollPlay);
  }

  render() {
    const { innerWidth, innerHeight } = window
    const { readersShown, cardContent, contentShown, currentImg } = this.state
    return (
      <div className={styles.block}>
        <HeaderMenu
          goToAbout={this.props.goToAbout}
          goToSpeakers={this.props.goToSpeakers}
          white
        />
        <div ref={this.videoBlock} className={styles.videoContainer}>
          <img
            ref={this.video}
            className={styles.video}
            style={{
              minHeight: `${innerHeight}px`,
              minWidth: `${innerWidth}px`
            }}
            alt="video"
          />
          {this.getCards()}
          <div
            onClick={this.toggleReaders}
            className={cn(styles.contentIsRead, {
              [styles.readersOpened]: readersShown,
              [styles.justWhite]: contentShown
            })}
          >
            <span>{!readersShown ? 'Повесть читают' : 'Свернуть'}</span>
          </div>
          <div className={cn(styles.contentIsReadList, { [styles.shown]: readersShown })}>
            <div ref={this.readersScrollContainer} className={styles.scrollable}>
              {this.getReadersList()}
            </div>
          </div>
          <div className={styles.shareContacts}>
            <span>Поделиться</span>
            <div className={styles.links}>
              <a rel="noopener noreferrer" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}>Facebook</a>
              <a rel="noopener noreferrer" target="_blank" href={`http://vk.com/share.php?url=${window.location.href}`}>VK</a>
              <a rel="noopener noreferrer" target="_blank" href={`https://twitter.com/home?status=${window.location.href}`}>Twitter</a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getImageName = (num, imagePath = 'one') => {
    const deltaPrec = 4 - ('' + num).length
    const nuls = '0'.repeat(deltaPrec)
    return `/video/${imagePath}/u${nuls}${num}.jpg`
  }
  scrollPlay = () => {
    if (this.props.shown) {
      const { scrollTop } = this.scrollContainer
      let currentImg = Math.ceil(scrollTop / playBackConst) + 1
      if (currentImg > frameCount) currentImg = frameCount

      if (this.currentImg !== currentImg) {
        
        if (this.secondCounter < currentImg) {
          this.video.current.src = this.getImageName(currentImg)
          
          if (this.loadTimeout) clearTimeout(this.loadTimeout)
          this.loadTimeout = setTimeout(() => {
            const img = new Image()
            img.src = this.getImageName(currentImg, 'two')
            img.addEventListener('load', e => {
              this.video.current.src = this.getImageName(currentImg, 'two')
            })
          }, 150)
        } else {
          this.video.current.src = this.getImageName(currentImg, 'two')
        }

        this.currentImg = currentImg
      }

      if (this.heightPerCard) {
        const currentCard = Math.floor(scrollTop / this.heightPerCard)

        if ((this.content[currentCard] &&
          this.state.currentCard !== currentCard) || this.state.prevOpenedSideBar !== this.state.openedSideBar
        ) {
          this.setState({ currentCard, prevOpenedSideBar: this.state.openedSideBar })
        }
      }
    }
    window.requestAnimationFrame(this.scrollPlay);
  }
  getCards = () => {
    const { currentCard } = this.state
    return this.content.map((el, i) => (
      <ContentCard
        key={'' + i}
        content={this.content[i]}
        side={i % 2 === 0 ? 'left' : 'right'}
        cardNum={currentCard}
        toggleContent={this.toggleContent}
        openedSideBar={currentCard === i && this.state.openedSideBar}
        active={currentCard === i}
        goToPart={this.goToPart}
      />
    ))
  }
  sliceVideoAndListenForCards = () => {
    this.heightPerCard = Math.floor((outerHeight -  window.innerHeight) / this.content.length)
  }
  getReadersList = () => {
    const content = unnest(this.content)
    const people = []
    content.forEach(({ name, cv, number }) => {
      people.push({
        name,
        cv,
        number
      })
    })

    return values(people).map(({ name, cv, number }, i) => (
      <div onClick={this.goToPart(+number - 1)} key={i} className={styles.item}>
        <h2>{name}</h2>
        <p>{cv}</p>
        <span className={styles.partNum}>Отрывок №{number}</span>
      </div>
    ))
  }
  toggleReaders = (switcher = true) => {
    this.scrollContainer.style.overflowY = switcher && !this.state.readersShown || this.state.contentShown ? 'hidden' : 'scroll'
    this.setState({ readersShown: switcher && !this.state.readersShown })
  }
  toggleContent = status => {
    this.scrollContainer.style.overflowY = status || this.state.readersShown ? 'hidden' : 'scroll'
    this.setState({ contentShown: status, openedSideBar: status })
  }
  goToPart = (index, toggleContent = false) => () => {
    this.toggleReaders(false)
    this.toggleContent(toggleContent)
    this.scrollContainer.scroll({
      top: Math.ceil(this.heightPerCard * index),
      left: 0,
      behavior: 'smooth'
    })
  }
}
