import React, { Component } from 'react'
import styles from './styles.module.scss'
import HeaderMenu from '../HeaderMenu'

export default class NotAvailable extends Component {
  render() {
    return (
      <div className={styles.block}>
        <HeaderMenu
          goToAbout={this.props.goToAbout}
          goToSpeakers={this.props.goToSpeakers}
          goToMain={this.props.goToMain}
          className={styles.header}
          mobile
        />
        <div className={styles.content}>
          <p>300-летию Невского проспекта посвящается</p>
          <h2>Мультимедийный проект «Невский проспект»</h2>
          <p>Уникальный мультимедийный проект – подарок городу и горожанам. В год, юбилейный для главной магистрали Петербурга, мы объединили больше 1000 человек. Знаменитые деятели культурны, лидеры мнений, представители власти читают отрывки из повести Николая Васильевича Гоголя «Невский проспект». Также, в рамках проекта, в городе прошли съемки полнометражного художественного фильма.</p>

          <div className={styles.warning}>
            <img src="/img/warning.svg" alt="not available" />
            <p>
              К сожалению сайт не поддерживает просмотр с устройства вашего разрешения.
          </p>
          </div>
        </div>
      </div>
    )
  }
}