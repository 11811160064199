import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom'

import './global.scss'

// route components
import Main from './components/Main'

const Root = () => (
  <BrowserRouter>
    <Route path="/" component={Main} />
  </BrowserRouter>
)

ReactDOM.render(<Root />, document.getElementById('root'));
