import React, { Component, Fragment } from 'react'
import cn from 'classnames'
import { unnest, values, pipe, reduce, map, splitAt } from 'ramda'
import styles from './styles.module.scss'
import HeaderMenu from '../HeaderMenu'

export default class ReadersScreen extends Component {
  constructor(props) {
    super(props)

    this.content = window.config.fullScreenReaders
  }
  render() {
    return (
      <div className={styles.block}>
        <HeaderMenu
          goToAbout={this.props.goToAbout}
          goToSpeakers={this.props.goToSpeakers}
          goToMain={this.props.goToMain}
          className={styles.header}
          black
          mobile
        />
        <div className={styles.topRow}>
          <span onClick={this.props.goToVideo} className={styles.goBack}>
            Вернуться к интерактивному видео
          </span>
        </div>
        <h2>Спикеры проекта</h2>
        <div className={cn(styles.contentIsReadList)}>
          {this.getReadersList()}
        </div>
      </div>
    )
  }

  getReadersList = () => {
    return pipe(
      unnest,
      reduce((acc, { name, cv, number }) => ([
        ...acc,
        {
          name,
          cv,
          number
        }
      ]), []),
      map(({ name, cv, number }, i) => (
        <div key={name + number} className={styles.item}>
          <h2>{name}</h2>
          <span className={styles.partNum}>Отрывок №{number}</span>
          <p>{cv}</p>
        </div>
      )),
      lines => {
        const [col1, col2] = splitAt(Math.ceil(lines.length / 2) + 1, lines)
        return (
          <Fragment>
            <div className={styles.col1}>
              {col1}
            </div>
            <div className={styles.col2}>
              {col2}
            </div>
          </Fragment>
        )
      }
    )(this.content)
  }
}
