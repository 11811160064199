import React, { Component, Fragment } from 'react'
import cn from 'classnames'
import styles from './styles.module.scss'

// screens
import MainScreen from '../MainScreen'
import FactsScreen from '../FactsScreen'
import VideoScreen from '../VideoScreen'
import AboutScreen from '../AboutScreen'
import ReadersScreen from '../ReadersScreen'
import NotAvailable from '../NotAvailable'

const screenStates = {
  main: 'mainScreen',
  loading: 'loadingScreen',
  interactive: 'videoScreen',
  about: 'about',
  speakers: 'speakers',
  notAvailable: 'notAvailable'
}

export default class Main extends Component {
  constructor(props) {
    super(props)

    const { innerHeight, innerWidth } = window

    this.state = {
      videoLoaded: false,
      videoLoadingState: 0,
      videoLoadedProgress: 0,
      screenState: this.isMobile() ? screenStates.notAvailable : screenStates.main,
      startFactSlide: false,
      mobileDetected: this.isMobile()
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      if (this.isMobile()) {
        if (!this.state.mobileDetected) {
          this.setState({
            mobileDetected: true,
            screenState: screenStates.notAvailable
          })
        }
      } else if (this.state.mobileDetected) {
        this.setState({
          mobileDetected: false,
          screenState: screenStates.main
        })
      }
    })
  }

  isMobile = () => window.innerWidth < 960 || window.innerHeight < 600

  render() {
    const { screenState } = this.state
    if (this.state.mobileDetected) {
      return (
        <Fragment>
          <div className={cn(styles.sepiabg, styles.screen, { [styles.shown]: screenState === screenStates.notAvailable })}>
            <NotAvailable
              goToAbout={this.goToAbout}
              goToSpeakers={this.goToSpeakers}
              goToMain={this.goToMainMobile}
            />
          </div>

          <div className={cn(styles.screen, { [styles.shown]: screenState === screenStates.about })}>
            <AboutScreen
              goToVideo={this.goToFacts}
              opened={screenState === screenStates.about}
              goToAbout={this.goToAbout}
              goToSpeakers={this.goToSpeakers}
              goToMain={this.goToMainMobile}
            />
          </div>

          <div className={cn(styles.screen, { [styles.shown]: screenState === screenStates.speakers })}>
            <ReadersScreen
              goToVideo={this.goToFacts}
              goToAbout={this.goToAbout}
              goToSpeakers={this.goToSpeakers}
              goToMain={this.goToMainMobile}
            />
          </div>
        </Fragment>
      )
    }

    const { startFactSlide, videoLoadedProgress, videoLoaded } = this.state

    if (screenState === screenStates.loading && !startFactSlide) {
      this.setState({ startFactSlide: true })
    }
    else if (startFactSlide && screenState !== screenStates.loading) {
      this.setState({ startFactSlide: false })
    }
    
    return (
      <Fragment>
        <div className={cn(styles.sepiabg, styles.screen, { [styles.shown]: screenState === screenStates.main })}>
          <MainScreen
            goToFacts={this.goToFacts}
            shown={screenState === screenStates.main}
          />
        </div>

        <div className={cn(styles.sepiabg, styles.screen, { [styles.shown]: screenState === screenStates.loading })}>
          <FactsScreen
            startFactSlide={startFactSlide}
            videoLoaded={videoLoaded}
            videoLoadedProgress={videoLoadedProgress}
            goToAbout={this.goToAbout}
            goToSpeakers={this.goToSpeakers}
            shown={screenState === screenStates.loading}
            goToVideo={this.goToVideo}
          />
        </div>

        <div id="scrollScreen" className={cn(styles.screen, { [styles.shown]: screenState === screenStates.interactive })}>
          <VideoScreen
            setProgress={this.setVideoLoadProgress}
            shown={screenState === screenStates.interactive}
            goToAbout={this.goToAbout}
            goToSpeakers={this.goToSpeakers}
          />
        </div>

        <div className={cn(styles.screen, { [styles.shown]: screenState === screenStates.about })}>
          <AboutScreen
            goToVideo={this.goToFacts}
            opened={screenState === screenStates.about}
          />
        </div>

        <div className={cn(styles.screen, { [styles.shown]: screenState === screenStates.speakers })}>
          <ReadersScreen
            goToVideo={this.goToFacts}
          />
        </div>
      </Fragment>
    )
  }

  goToFacts = () => {
    this.setState({ screenState: this.isMobile() ? screenStates.notAvailable : screenStates.loading })
  }

  goToVideo = () => {
    if (this.state.videoLoaded) {
      this.setState({ screenState: screenStates.interactive })
    }
  }

  goToAbout = () => {
    this.setState({ screenState: screenStates.about })
  }

  goToSpeakers = () => {
    this.setState({ screenState: screenStates.speakers })
  }

  goToMainMobile = () => {
    this.setState({ screenState: screenStates.notAvailable })
  }

  setVideoLoaded() {
    this.setState({ videoLoaded: true })
  }

  setVideoLoadProgress = (progress) => {
    this.setState({ videoLoadedProgress: progress }, () => {
      if (progress === 100) {
        this.setState({ videoLoaded: true })
      }
    })
  }
}
